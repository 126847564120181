import React, { Component } from "react";
import styled from "styled-components";
import { graphql, Link, StaticQuery } from "gatsby";
import Img from "gatsby-image";
// import {
//   IoMdMore, IoMdStarOutline, IoMdHeartEmpty
//   , IoMdArrowRoundForward} fr../../utilsons/io";
// import { fullstackIco../../utilscon } from "../../utils/";

/************************************/
/**         BlogCard Class          */
/************************************/
class BlogCard extends Component {
  constructor() {
    super();
    this.state = {
      alignLeft: true
    };
  }

  handleAlignLeft = e => {
    // this.setState({ loopActive: !this.state.loopActive })
    let align = this.state.alignLeft;
    console.log(align);
    this.setState({ alignLeft: !align });
    return align;
  };

  render() {
    // handleAlignLeft() {
    //   let align = this.state.alignLeft;
    //   console.log(align);
    //   this.setState({ alignLeft: !align });
    //   return align;
    // }
    return (
      <BlogCardWrapper>
        <StaticQuery
          query={PostsDataQUERY}
          render={data => {
            const posts = data.postsData.edges;
            return posts.map(post => {
              return (
                <div
                  className={
                    post.node.alignLeft ? "blog-card" : "blog-card alt"
                  }
                  key={post.node.slug}
                >
                  <div className="meta-div">
                    <Img className="photo" fluid={post.node.heroImage.fluid} />
                    {/* {service.icon} */}
                  </div>
                  <div className="description">
                    <Link to={post.node.slug}>
                      <h1>{post.node.title} </h1>
                      <h2>{post.node.subTitle}</h2>
                      <p>{post.node.shortDescription.shortDescription}</p>
                      <p className="read-more">
                        <span className="aLink">Read More</span>
                      </p>
                    </Link>
                  </div>
                </div>
              );
            });
          }}
        />
      </BlogCardWrapper>
    );
  }
}
const PostsDataQUERY = graphql`
  {
    postsData: allContentfulPostCards {
      edges {
        node {
          index
          title
          subTitle
          heroImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          alignLeft
          publishDate(formatString: "DD MMMM, YYYY")
          slug
          shortDescription {
            shortDescription
          }
        }
      }
    }
  }
`;
/************************************/
/**       BlogCardWrapper SC        */
/************************************/
const BlogCardWrapper = styled.div`
  margin: 3rem 0rem;

  * {
    box-sizing: border-box;
  }

  background: #f1f1f1;
  padding: 1rem;

  .blog-card {
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.6%;
    background: #fff;
    line-height: 1.4;
    /* font-family: sans-serif; */
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;
  }
  .blog-card .aLink {
    color: inherit;
  }
  .blog-card .aLink:hover {
    color: #5ad67d;
  }
  .blog-card:hover .photo {
    transform: scale(1.3) rotate(3deg);
  }
  .blog-card .meta-div {
    position: relative;
    z-index: 0;
    height: 200px;
  }
  .blog-card .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: transform 0.2s;
  }

  /* .blog-card .details,
  .blog-card .details ul {
    margin: auto;
    padding: 0;
    list-style: none;
  }
  .blog-card .details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    margin: auto;
    transition: left 0.2s;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 10px;
    width: 100%;
    font-size: 0.9rem;
  }
  .blog-card .details a {
    text-decoration: dotted underline;
  }
  .blog-card .details ul li {
    display: inline-block;
  }
  .blog-card .details .author:before {
    font-family: FontAwesome;
    margin-right: 10px;
    content: "\f007";
  }
  .blog-card .details .date:before {
    font-family: FontAwesome;
    margin-right: 10px;
    content: "\f133";
  }
  .blog-card .details .tags ul:before {
    font-family: FontAwesome;
    content: "\f02b";
    margin-right: 10px;
  }
  .blog-card .details .tags li {
    margin-right: 2px;
  }
  .blog-card .details .tags li:first-child {
    margin-left: -4px;
  } */
  .blog-card .description {
    padding: 1rem;
    background: #fff;
    position: relative;
    z-index: 1;
  }
  .blog-card .description h1,
  .blog-card .description h2 {
    text-transform: capitalize;
  }
  .blog-card .description h1 {
    line-height: 1;
    margin: 0.8rem 0rem;
    font-size: 1.4rem;
  }
  .blog-card .description h2 {
    font-size: 1rem;
    font-weight: 300;
    text-transform: capitalize;
    color: #a2a2a2;
    margin-top: 5px;
  }
  .blog-card .description .read-more {
    text-align: right;
  }
  .blog-card .description .read-more .aLink {
    color: #5ad67d;
    /* color: #005bea; */

    display: inline-block;
    position: relative;
  }
  .blog-card .description .read-more .aLink:after {
    content: "=>";
    /* font-family: FontAwesome; */
    margin-left: -10px;
    opacity: 0;
    vertical-align: middle;
    transition: margin 0.3s, opacity 0.3s;
  }
  .blog-card .description .read-more .aLink:hover:after {
    margin-left: 5px;
    opacity: 1;
  }
  .blog-card p {
    position: relative;
    margin: 1rem 0 0;
  }
  .blog-card p:first-of-type {
    margin-top: 1.25rem;
  }
  .blog-card p:first-of-type:before {
    content: "";
    position: absolute;
    height: 2px;
    background: #5ad67d;
    /* background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%); */
    width: 140px;
    top: -0.75rem;
    border-radius: 3px;
  }
  /* .blog-card:hover .details {
    left: 0%;
  } */
  @media (min-width: 640px) {
    .blog-card {
      flex-direction: row;
      max-width: 80%;
    }
    .blog-card .meta-div {
      flex-basis: 40%;
      height: auto;
    }
    .blog-card .description {
      flex-basis: 60%;
    }
    .blog-card .description:before {
      transform: skewX(3deg);
      content: "";
      background: #fff;
      width: 30px;
      position: absolute;
      left: -10px;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
    .blog-card.alt {
      flex-direction: row-reverse;
    }
    .blog-card.alt .description:before {
      left: inherit;
      right: -10px;
      transform: skew(3deg);
    }
    /* .blog-card.alt .details {
      padding-left: 25px;
    } */
  }
`;
export default BlogCard;
