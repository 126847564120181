import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { BlogHeader, BannerDefault, Section, Title } from "../utils/";
// import blogImg from "../utils/img/bcg/blogbcg.jpeg";
import { graphql, StaticQuery } from "gatsby";
import BlogCard from "../components/04-blog/BlogCard";

const BlogsPage = ({
  dhTitle,
  dhSubtitle,
  dsectionTitle1,
  dsectionSubTitle1
}) => (
  <Layout>
    <SEO
      title="XBound"
      keywords={[
        `smarta kontrakt sverige svenskt företag`,
        ` svenskt företag blockchain utveckling sverige`,
        `Blockchain development smarta kontrakt och blockchain utveckling`,
        `Smart Contract development `,
        `Ethereum Development sverige eterumutveckling `,
        `Solidity Development svenskt företag `,
        `Hyperledger Development Sweden`,
        `ICO STO Development Sweden Stockholm Malmo`,
        `Smart contract Audit Sweden smart kontraktskontroll`,
        `Blockchain Consulting and Development Sweden Stockholm Malmo`,
        `Full-Stack Development Sweden Stockholm Malmo`,
        `svensk blockchain utveckling sverige`,
        `svensk blockchain smart kontraktsutveckling`,
        `Full-Stack Development services sverige`,
        `blockchain company Sweden`,
        `ERC20 Ethereum Token Development Sweden`,
        `Swedish Ethereum Development ICO`,
        `gatsby smarta kontrakt och blockchain utveckling sverige`,
        `react sverige svenskt företag `,
        `Nodejs sverige svenskt företag `,
        `JAMStack Swedish Sweden Stockholm Malmo`,
        `Gatsby Website development Sweden`,
        `React Redux Graphql Front developer svenskt företag `,
        `React Redux Graphql Backend development Sweden Stockholm Malmo`,
        `Cryptocurency development Sweden`,
        `Wallet development Cryptocurency Smart Contract`,
        `Website development Sweden`,
        `Website blog hemsida utveckling Sweden Stockholm Malmo`,
        `Website blog hemsida utveckling svenskt företag`
      ]}
    />
    <StaticQuery
      query={POSTPAGEQUERY}
      render={data => {
        const postPage = data.postPageData.edges[0];
        const {
          hTitle,
          hSubtitle,
          sectionTitle1,
          sectionSubTitle1
        } = postPage.node;
        return (
          <div>
            {/* <BlogHeader img={blogImg}> */}
            <BlogHeader>
              <BannerDefault
                title={hTitle || dhTitle}
                subtitle={hSubtitle || dhSubtitle}
              />
            </BlogHeader>
            <Section>
              <Title
                title={sectionTitle1 || dsectionTitle1}
                message={sectionSubTitle1 || dsectionSubTitle1}
              />
            </Section>
            <BlogCard />
          </div>
        );
      }}
    />
  </Layout>
);

const POSTPAGEQUERY = graphql`
  {
    postPageData: allContentfulPostPage {
      edges {
        node {
          hTitle
          hSubtitle
          sectionTitle1
          sectionSubTitle1
        }
      }
    }
  }
`;

BlogsPage.defaultProps = {
  dhTitle: "Welcome to XBound's Blog Page_",
  dhSubtitle:
    "Here where you’ll find out about our latest blog and news, so be sure to come back and visit soon_",
  dsectionTitle1: "Our latest posts published by our team_",
  dsectionSubTitle1:
    " Explore our great blogs posted by our team for insightful articles and resources  that inspire action on the topics you care about_"
};

export default BlogsPage;
